import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import {
  Length,
  ResponsiveValue,
} from "@chakra-ui/styled-system/dist/types/utils";
import * as CSS from "csstype";
import React from "react";
import { heroText } from "../../../constants/strings";

type OwnProps = {
  bg?: string;
  bgColor?: string;
  h?: ResponsiveValue<CSS.Property.Height<Length>>;
  breadcrumb?: React.ReactNode;
};

export const MainHero: React.FC<OwnProps> = ({
  bg,
  bgColor = "accent.01",
  breadcrumb,
  h = [460, 500],
}) => {
  const background =
    (bg &&
      `
      radial-gradient(at top center, transparent -20%, rgba(0,0,0,0.55) 80%),
      url(${bg}) top center / cover no-repeat,
      #eee`) ||
    bgColor ||
    "#ccc";
  return (
    <Box as={"section"} id={"hero"} position={"relative"} h={h} bg={background}>
      {breadcrumb ? (
        <Container maxW="container.md" position="relative" color="white" py={2}>
          <Box>{breadcrumb}</Box>
        </Container>
      ) : null}
      <Flex
        align={"center"}
        justify={"center"}
        height={"full"}
        color={"utils.white"}
      >
        <Box textAlign={"center"} px={2}>
          <Heading
            as={"h1"}
            fontSize={[38, 50, 64]}
            mb={3}
            textShadow={"0 2px 8px rgba(0,0,0,0.43)"}
          >
            {heroText.heading}
          </Heading>

          <Text
            fontSize={["lg", "xl"]}
            px={[12, 0]}
            textShadow={"0 2px 8px rgba(0,0,0,0.83)"}
            fontWeight={"normal"}
          >
            {heroText.text}
          </Text>
        </Box>
      </Flex>
    </Box>
  );
};
