import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Switch,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import debounce from "lodash/debounce";
import React from "react";
import { useCallback, useEffect, useState } from "react";
import { GOOGLE_API_KEY } from "../../../../constants/config";
import stores from "../../../../content/data/storeLocatorData.json";
import {
  geocodeAddress,
  geolocation,
  getFakeBounds,
} from "../../../../lib/gmaps/gmaps";
import { IFiliale } from "../../../../types/dealers";
import { Headline } from "../../../Headline";
import Section from "../../../Section";
import NavigationIcon from "../../../shared/icons/NavigationIcon";
import { ErrorBoundary } from "./ErrorBoundary";
import StoreLocatorMap, { IMapCoords } from "./StoreLocatorMap";

const Number = styled.span`
  background-color: ${({ theme }) =>
    theme?.colors?.hover?.["01"] ?? "rgba(0,0,0,.1)"};
  color: #ffffff;
  display: inline-block;
  font-size: 0.875rem;
  line-height: 1.5rem;
  height: 1.5rem;
  text-align: center;
  width: 1.5rem;
  margin-right: 0.5rem;
`;

export enum FilterKeys {
  "hyundai" = "HY",
  "kia" = "KA",
}

interface IFilters {
  [FilterKeys.hyundai]: boolean;
  [FilterKeys.kia]: boolean;
}

const defaultFilters: IFilters = {
  [FilterKeys.hyundai]: true,
  [FilterKeys.kia]: true,
};

const defaultPosition: IMapCoords = {
  bounds: {
    north: 0,
    east: 0,
    south: 0,
    west: 0,
  },
  center: { lat: 41.29085, lng: 11.2297381 },
  zoom: 6,
};

const googleMapUrl = `https://maps.googleapis.com/maps/api/js?v=3.41&key=${GOOGLE_API_KEY}&language=it&region=IT`;

const filterPoints = setFilteredStores => (filters, position) => {
  const { north, east, south, west } = position.bounds;

  const filterStoresByType = (store: IFiliale) =>
    (filters[FilterKeys.hyundai] && store.tipo === FilterKeys.hyundai) ||
    (filters[FilterKeys.kia] && store.tipo === FilterKeys.kia);

  const onlyVisibleStores = (store: IFiliale) =>
    // if bounds are not set, show all the stores
    (south === 0 && north === 0 && west === 0 && east === 0) ||
    (store.latitudine >= south &&
      store.latitudine <= north &&
      store.longitudine >= west &&
      store.longitudine <= east);

  // This is a temporary fix to clean-up the data,
  // since a large number of stores is duplicate
  const _stores = stores.reduce((acc, curr) => {
    const isDupe = acc.some(
      item =>
        item.latitudine + item.longitudine ===
        curr.latitudine + curr.longitudine
    );

    if (!isDupe) {
      acc.push(curr);
    }

    return acc;
  }, []);

  const filtered = (_stores as unknown as IFiliale[])
    .filter(onlyVisibleStores)
    .filter(filterStoresByType)
    .slice(0, 100);

  setFilteredStores(filtered);
};

const changePosition = setPosition =>
  debounce((newCoords: IMapCoords) => {
    if (newCoords) {
      setPosition(newCoords);
    }
  }, 1000);

export const EnabledStoreLocator: React.FC = () => {
  const [position, setPosition] = useState<IMapCoords>(defaultPosition);
  const [searchLabel, setSearchLabel] = useState<string>("");
  const [filters, setFilters] = useState<IFilters>(defaultFilters);
  const [filteredStores, setFilteredStores] = useState<IFiliale[]>([]);

  const setFilterPoints = useCallback(filterPoints(setFilteredStores), [
    setFilteredStores,
  ]);

  const moveHandler = useCallback(changePosition(setPosition), [setPosition]);

  useEffect(() => {
    setFilterPoints(filters, position);
  }, [filters, position]);

  const enableLocalizationHandler = () => {
    geolocation()
      .then(p => {
        if (p) {
          // IMPORTANT: [!] Do not change zoom level. Read getFakeBounds's jsDoc for more info.
          setPosition({
            bounds: getFakeBounds(p),
            center: p,
            zoom: 12, // [!]
          });
        }
      })
      .catch(() => {
        console.warn("[Geolocation] Geolocation denied");
      });
  };

  const searchChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchLabel(e.target.value);
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (searchLabel) {
      geocodeAddress(searchLabel)
        .then(results => {
          const [result] = results;
          if (result) {
            setPosition({
              bounds: result.geometry.bounds.toJSON(),
              center: result.geometry.location.toJSON(),
              zoom: 12,
            });
          }
        })
        .catch(() => {
          console.warn("[Geocode] Error while Geocoding");
        });
    }
  };

  const toggleFilter = (brand: FilterKeys) => () => {
    setFilters(filter => ({
      ...filter,
      [brand]: !filter[brand],
    }));
  };

  const hSwitchName = FilterKeys.hyundai + "_stores";
  const kSwitchName = FilterKeys.kia + "_stores";

  return (
    <ErrorBoundary>
      <Section
        as={"div"}
        className="store-locator-enabled"
        bg="accent.01"
        color="utils.white"
        containerProps={{
          maxWidth: "container.lg",
        }}
      >
        <Grid templateColumns={["1fr", null, "minmax(280px, 1fr) 2fr"]} gap={8}>
          <Box w="100%">
            <form onSubmit={submitHandler}>
              <Headline textAlign="left">Dove trovarci</Headline>

              <Text fontSize="lg" mb={10}>
                Per ricevere informazioni sui prodotti che vuoi acquistare e sui
                finanziamenti
              </Text>

              <Box mb={10}>
                <Box mb={4}>
                  <Text fontSize="lg">
                    <Number>1</Number> Seleziona di chi hai bisogno
                  </Text>
                </Box>

                <FormControl
                  display="flex"
                  alignItems="center"
                  py={4}
                  justifyContent={"space-between"}
                >
                  <FormLabel htmlFor={hSwitchName} mb="0" fontSize={"1.1rem"}>
                    Concessionari Hyundai
                  </FormLabel>

                  <Switch
                    id={hSwitchName}
                    colorScheme={"whiteAlpha"}
                    size={"lg"}
                    onChange={toggleFilter(FilterKeys.hyundai)}
                    isChecked={filters[FilterKeys.hyundai]}
                  />
                </FormControl>

                <FormControl
                  display="flex"
                  alignItems="center"
                  py={4}
                  justifyContent={"space-between"}
                >
                  <FormLabel htmlFor={kSwitchName} mb="0" fontSize={"1.1rem"}>
                    Concessionari Kia
                  </FormLabel>
                  <Switch
                    id={kSwitchName}
                    colorScheme={"whiteAlpha"}
                    size={"lg"}
                    onChange={toggleFilter(FilterKeys.kia)}
                    isChecked={filters[FilterKeys.kia]}
                  />
                </FormControl>
              </Box>

              <Box mb={10}>
                <Box mb={4}>
                  <Text fontSize="lg">
                    <Number>2</Number> Inserisci la posizione
                  </Text>
                </Box>
                <InputGroup size="md">
                  <Input
                    border="2px"
                    borderColor="utils.offWhite"
                    borderRadius={0}
                    onChange={searchChangeHandler}
                    placeholder="Inserisci indirizzo, città o CAP"
                    size="lg"
                    value={searchLabel}
                    _focus={{ borderColor: "utils.white" }}
                  />
                  <InputRightElement>
                    <IconButton
                      aria-label="Abilita la Geolocalizzazione"
                      bg="utils.offWhite"
                      border="2px"
                      borderColor="utils.offWhite"
                      borderRadius={0}
                      color="accent.03"
                      icon={<NavigationIcon />}
                      onClick={enableLocalizationHandler}
                      size="lg"
                      variant="outline"
                      top={"4px"}
                      right={"4px"}
                      _hover={{
                        bg: "hover.02",
                      }}
                    />
                  </InputRightElement>
                </InputGroup>
              </Box>

              <Box my={4}>
                <Button
                  w={["full", null, null, "auto"]}
                  bg="hover.01"
                  borderRadius={0}
                  color="utils.white"
                  display="block"
                  fontWeight="400"
                  h="auto"
                  px="60px"
                  py="16px"
                  textAlign="center"
                  textTransform="uppercase"
                  type="submit"
                  _hover={{
                    textDecoration: "none",
                    bg: "utils.black",
                    color: "utils.white",
                  }}
                >
                  CERCA
                </Button>
              </Box>
            </form>
          </Box>
          <Box w="100%" h="620px">
            <StoreLocatorMap
              googleMapURL={googleMapUrl}
              loadingElement={<div style={{ height: "100%" }} />}
              containerElement={<div style={{ height: "100%" }} />}
              mapElement={<div style={{ height: "100%" }} />}
              onMapMove={moveHandler}
              center={position.center}
              zoom={position.zoom}
              stores={filteredStores}
            />
          </Box>
        </Grid>
      </Section>
    </ErrorBoundary>
  );
};
