/* ---------------------------------
Home (index.tsx)
--------------------------------- */

import React from "react";
import { Claim } from "../components/Claim";
import { Head } from "../components/Head";
import Layout from "../components/Layout";
import { AboutUs } from "../components/page-sections/homepage/AboutUs";
import { AskAnExpert } from "../components/page-sections/homepage/AskAnExpert";
import Faq from "../components/page-sections/homepage/Faq";
import { MainHero } from "../components/page-sections/homepage/MainHero";
// import { NoNews } from "../components/page-sections/homepage/News/NoNews";
import News from "../components/page-sections/homepage/News/News";
import { ProdottiServizi } from "../components/page-sections/homepage/ProdottiServizi";
import { StoreLocator } from "../components/page-sections/homepage/StoreLocator/StoreLocator";
import heroBg from "../img/hero.jpg";

const Home: React.FC = () => {
  return (
    // <CookiesProvider>
    <Layout>
      <Head />

      {/* HERO */}
      <MainHero bg={heroBg} />

      <Claim />

      {/* CHI-SIAMO */}
      <AboutUs />

      {/* PRODOTTI & SERVIZI */}
      <ProdottiServizi />

      {/* NEWS */}
      <News />
      {/* <NoNews /> */}

      {/* DOVE TROVARCI */}
      <StoreLocator />

      {/* FAQ */}
      <Faq />

      {/* ASK AN EXPERT */}
      <AskAnExpert />
    </Layout>
    // </CookiesProvider>
  );
};

export default Home;
