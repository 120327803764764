import { Heading, Text } from "@chakra-ui/react";
import React from "react";
import { MenuIds } from "../../../../types/types";
import { BrandedButton } from "../../../BrandedButton";
import Section from "../../../Section";
import { ERROR_PROCESSING_GEO_DATA } from "../../../../constants/error-messages";

type Props = {
  resetAction?: () => void;
};

export const ErrorMessage: React.FC<Props> = ({ resetAction }) => (
  <Section
    id={MenuIds["Dove trovarci"]}
    bg="utils.gray.01"
    containerProps={{
      maxWidth: "container.lg",
    }}
  >
    <Heading as="h3" fontSize="32px" mb={6}>
      Dove trovarci
    </Heading>
    <Text fontSize="lg" color="utils.gray.02" mb={10}>
      {ERROR_PROCESSING_GEO_DATA}
    </Text>
    <BrandedButton buttonLabel="Riprova" buttonAction={resetAction} />
  </Section>
);
