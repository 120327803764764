/* ---------------------------------
News
--------------------------------- */

import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { makeSlug } from "../../../../utils";
import { Headline } from "../../../Headline";
import Section from "../../../Section";
import { NewsItem } from "./NewsItem";

const News: React.FC = () => {
  // TODO better sorting
  const data = useStaticQuery(graphql`
    {
      allFile(
        filter: { sourceInstanceName: { eq: "news" }, ext: { eq: ".md" } }
        sort: { order: DESC, fields: birthTime }
        limit: 5
      ) {
        edges {
          node {
            id
            childMarkdownRemark {
              frontmatter {
                title
                timestamp
                thumbnail {
                  childImageSharp {
                    fixed {
                      src
                    }
                  }
                }
              }
              excerpt(pruneLength: 70)
            }
          }
        }
      }
    }
  `);

  return (
    <Section id={"news"} bg="utils.white">
      <Headline>News</Headline>

      {data.allFile.edges.map(
        ({
          node: {
            id,
            childMarkdownRemark: { frontmatter, excerpt },
          },
        }) =>
          // i: number
          {
            return (
              <NewsItem
                key={id}
                timestamp={frontmatter?.timestamp}
                title={frontmatter?.title}
                body={excerpt}
                image={frontmatter?.thumbnail?.childImageSharp?.fixed?.src}
                buttonNavUrl={
                  makeSlug(
                    [frontmatter?.timestamp, frontmatter?.title],
                    "news"
                  ) ?? "/news"
                }
              />
            );
          }
      )}
    </Section>
  );
};

export default News;
