import { Heading, Link, ListItem, UnorderedList } from "@chakra-ui/react";
import React from "react";
import { IProdottiData } from "../../components/page-sections/homepage/ProdottiServizi";
import { TextParagraph } from "../../components/TextParagraph";

const CustomHeading: React.FC = ({ children, ...props }) => (
  <Heading
    as="h6"
    fontFamily="Arial, sans-serif, system-ui"
    fontSize="lg"
    {...props}
  >
    {children}
  </Heading>
);

export const data: IProdottiData[] = [
  {
    title: "Finanziamento",
    image: "/images/prodotti-finanziamento.png",
    body: (
      <>
        <TextParagraph>
          Con HYUNDAI Finance e KIA Finance, offriamo ai nostri clienti
          l'opportunità di soddisfare il loro desiderio di mobilità dando loro
          un margine finanziario per altri investimenti.
        </TextParagraph>
        <CustomHeading>Finanziamento classico</CustomHeading>
        <TextParagraph>
          La soluzione di mobilità per i clienti che vogliono acquisire la
          proprietà dell’auto con la possibilità di modulare la durata del
          finanziamento e, dunque, l’importo mensile adattandolo alle proprie
          esigenze di spesa mensile.
        </TextParagraph>
        <CustomHeading>
          Finanziamento Trade Cycle Management (TCM)
        </CustomHeading>
        <TextParagraph>
          E’ la soluzione di mobilità con la quale il cliente garantisce la sua
          mobilità e la proprietà del veicolo. Grazie al Valore Futuro Garantito
          il cliente rimborsa piccole quote mensili e rimane libero di
          scegliere, al termine del contratto se:
        </TextParagraph>
        <UnorderedList>
          <ListItem>
            1. Rinnovare :<br />
            Restituisci la tua Auto<sup>(1)</sup> al tuo concessionario e
            rinnova acquistando una nuova Auto.
          </ListItem>
          <ListItem>
            2. Tenere :<br />
            Tieni la tua Auto, pagando o rifinanziando il Valore Futuro
            Garantito stabilito da contratto.
          </ListItem>
          <ListItem>
            {" "}
            3. Restituire <br />
            Restituisci la tua Auto al tuo concessionario.
          </ListItem>
        </UnorderedList>
        <br />
        <TextParagraph fontSize="xs">
          <sup>(1)</sup> L'opzione di restituzione si basa su un accordo
          aggiuntivo al Contratto di finanziamento TCM Hyundai o Kia, che viene
          concluso direttamente tra te e il tuo concessionario. A tal fine si
          applicano le condizioni di riacquisto ivi concordate.
        </TextParagraph>

        <TextParagraph fontSize="xs">
          Annuncio pubblicitario con finalità promozionale. Per tutte le
          condizioni contrattuali ed economiche consultare le “Informazioni
          europee di base sul credito ai consumatori” disponibili presso i
          concessionari e sul sito{" "}
          <Link
            href="https://www.hyundaicapitalitaly.com"
            isExternal
            color={"accent.01"}
          >
            www.hyundaicapitalitaly.com
          </Link>
          , sezione Trasparenza. Salvo approvazione di Hyundai Capital Europe
          GmbH Italy
        </TextParagraph>
      </>
    ),
  },
  {
    title: "Leasing",
    image: "/images/prodotti-leasing.png",
    body: (
      <>
        <TextParagraph>
          L’opportunità di non avere la proprietà dell’auto, ma solo l’utilizzo,
          mantendo, a condizioni di riscatto prestabilito, la possibilità di
          acquisire l’auto al termine del contratto.
        </TextParagraph>
        {/* <CustomHeading>Leasing Finanziario </CustomHeading>
        <TextParagraph>
          E’ rivolto a coloro che cercano il semplice utilizzo della vettura
          attraverso il pagamento di canoni mensili modulari e che si riservano
          il diritto di acquistare l’auto al termine del contratto di locazione.
        </TextParagraph> */}
        <CustomHeading>Leasing a valore residuo</CustomHeading>
        <TextParagraph>
          Questa variante è stata sviluppata per i clienti che non sanno
          esattamente quanti chilometri percorrono ogni anno. La rata mensile
          dipende dal valore residuo precalcolato del veicolo al termine del
          contratto.
        </TextParagraph>
        <TextParagraph fontSize="xs">
          Annuncio pubblicitario con finalità promozionale. Per tutte le
          condizioni contrattuali ed economiche consultare le “Informazioni
          europee di base sul credito ai consumatori” disponibili presso i
          concessionari e sul sito{" "}
          <Link
            href="https://www.hyundaicapitalitaly.com"
            isExternal
            color={"accent.01"}
          >
            www.hyundaicapitalitaly.com
          </Link>
          , sezione Trasparenza. Salvo approvazione di Hyundai Capital Europe
          GmbH Italy
        </TextParagraph>
      </>
    ),
  },
  {
    title: "Prodotti assicurativi",
    // image: "/images/hero-hybrid.jpg",
    body: (
      <>
        <TextParagraph>
          L’offerta finanziaria include alcuni servizi assicurativi, opzionali,
          che il Cliente può scegliere per proteggere se stesso, la famiglia e
          la vettura che ha acquistato da ogni possibile rischio.
        </TextParagraph>
        <CustomHeading>Credito Protetto</CustomHeading>
        <TextParagraph>
          La polizza che protegge il cliente in caso di malattia prolungata o
          incidente o di perdita del posto di lavoro. La compagnia assicurativa
          si farà carico, al verificarsi di una delle eventualità sopra
          menzionate, del pagamento delle rate anche fino all’estensione
          integrale del debito residuo.
        </TextParagraph>
        <CustomHeading>Polizza Furto &amp; Incendio</CustomHeading>
        <TextParagraph>
          La polizza che protegge la vettura dai danni accidentali del veicolo:
          furto dell’auto, incendio, atti vandalici, eventi atmosferici e danni
          ai cristalli. La polizza può essere integrata anche con l’opzione
          Collision (danni alla vettura in seguito ad un incidente con colpa con
          altro veicolo) oppure l’opzione Kasko (danni alla vettura in seguito
          ad un incidente di qualunque genere, non coperto da RCA della
          controparte).
        </TextParagraph>
        <TextParagraph fontSize="xs">
          Annuncio pubblicitario con finalità promozionale. Informazioni e
          Condizioni nel Set Informativo alla sezione Trasparenza. Salvo
          approvazione di Hyundai Capital Bank Europe GmbH Italy
        </TextParagraph>
        <TextParagraph fontSize="xs">
          Hyundai Capital Bank Europe GmbH Italy, Sede secondaria in Italia:
          Corso Massimo d'Azeglio, 33/E 10126 Torino, telefono 011-6318911,
          posta elettronica certificata{" "}
          <Link
            href="mailto:hcbeitaly@actaliscertymail.it"
            textDecor={"underline"}
            isExternal
          >
            hcbeitaly@actaliscertymail.it
          </Link>{" "}
          - è un intermediario assicurativo iscritto nell'Elenco annesso al
          Registro degli intermediari assicurativi, anche a titolo accessorio, e
          riassicurativi (RUI), al numero UE00011544 e in possesso
          dell'abilitazione all'esercizio dell'attività in Italia. Gli estremi
          identificativi e di iscrizione dell'intermediario possono essere
          verificati consultando il RUI sul sito internet dell'IVASS (
          <Link href="www.ivass.it" textDecor="underline" isExternal>
            www.ivass.it
          </Link>
          ). L'attività di intermediazione assicurativa è soggetta alla
          vigilanza dell'IVASS (Istituto per la vigilanza sulle assicurazioni)
          quale autorità competente. Hyundai Capital Bank Europe GmbH - Sede
          Legale: Francoforte sul Meno Friedrich-Ebert-Anlage 35-37, Germania, è
          intermediario assicurativo registrato in Germania con numero di
          iscrizione D-PD2H-95U0M-78, verificabile sul sito{" "}
          <Link
            href="https://www.vermittlerregister.info/"
            textDecor={"underline"}
            isExternal
          >
            https://www.vermittlerregister.info/
          </Link>{" "}
          e soggetto alla vigilanza dell'Autorità Deutsche Industrie
          und-Handelskammer (DIHK). E' facoltà del contraente, ferma restando la
          possibilità di rivolgersi all'Autorità giudiziaria, di inoltrare per
          iscritto i reclami relativi al comportamento dell'intermediario
          inviando una comunicazione a: HCBE Italy - Ufficio Reclami - Corso
          Massimo D'Azeglio, n. 33/E, 10126 Torino Italia - email:{" "}
          <Link
            href="mailto:reclami@it.hcs.com"
            textDecor={"underline"}
            isExternal
          >
            reclami@it.hcs.com
          </Link>{" "}
          - PEC:{" "}
          <Link
            href="mailto:reclami.hcbeitaly@actaliscertymail.it"
            textDecor={"underline"}
            isExternal
          >
            reclami.hcbeitaly@actaliscertymail.it
          </Link>{" "}
          Inoltre il contraente ha facoltà di avvalersi dei sistemi di
          risoluzione stragiudiziale delle controversie previsti dalla normativa
          vigente.
        </TextParagraph>
      </>
    ),
  },
];
