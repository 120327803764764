import {
  Box,
  Button,
  Flex,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { IFiliale } from "../../../../types/dealers";
import { PhoneIcon, PinIcon } from "../../../Icons";
import { FilterKeys } from "./EnabledStoreLocator";

interface IProps {
  store: IFiliale;
}

const cleanNumber = (phoneNumber: string): string =>
  phoneNumber
    .split("")
    .filter(char => char.match(/[0-9]/))
    .join("");

const StoreInfoWindow: React.FC<IProps> = ({ store }) => {
  return (
    <Box
      bg="white"
      p={".8rem"}
      fontWeight={400}
      fontSize={"14px"}
      color={"black"}
    >
      {/* Store Name */}
      <Box mb={4}>
        <Box textAlign="center">
          {store.tipo === FilterKeys.hyundai
            ? "Concessionaria Hyundai"
            : store.tipo === FilterKeys.kia
            ? "Concessionaria Kia"
            : ""}
        </Box>
        <Box textAlign="center">
          <Text mb={".5rem"}>
            <strong dangerouslySetInnerHTML={{ __html: store.nome }} />
          </Text>
        </Box>
      </Box>

      {/* Content */}
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={12}
        align="stretch"
      >
        <Box>
          {/* Address */}
          <Box my={4}>
            <Flex>
              <PinIcon w={"20px"} h={"20px"} />
              <Box ml={2}>
                <span dangerouslySetInnerHTML={{ __html: store.indirizzo }} />
                <br />
                {store.cap}{" "}
                <span dangerouslySetInnerHTML={{ __html: store.localita }} />{" "}
                <br />
                <span
                  dangerouslySetInnerHTML={{ __html: store.provincia }}
                />{" "}
                <br />
              </Box>
            </Flex>
          </Box>

          {/* Distance */}
          {/*<Box my={4}>
            Distanza:{" "}
            {store.dist > 1
              ? `${Math.round(store.dist)} km`
              : `${store.dist * 1000} m`}
          </Box>*/}

          {/* Contact informations*/}
          <Box>
            {store.telefono ? (
              <Box>
                <Button
                  as="a"
                  variant="ghost"
                  // for some reason object access
                  // (like `accent.01`) to the theme
                  // is not resolved here.
                  color={"#2F79D9"}
                  size="xs"
                  href={`tel:${cleanNumber(store.telefono)}`}
                  leftIcon={<PhoneIcon w={"16px"} h={"16px"} />}
                >
                  {store.telefono}
                </Button>
              </Box>
            ) : null}
            {store.fax ? <Box>Fax: {store.fax}</Box> : null}
          </Box>
        </Box>
        <Box>
          <Button
            as="a"
            target="_blank"
            href={`https://www.google.com/maps/dir/?api=1&destination=${store.latitudine},${store.longitudine}`}
            isFullWidth
            color={"#2F79D9"}
          >
            Indicazioni stradali
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

export default StoreInfoWindow;
