import { ListItem, UnorderedList } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { StyledLink } from "../../components/StyledLink";
import { TextParagraph as TextParagraphOriginal } from "../../components/TextParagraph";
import { HideUntil } from "../../lib/HideUntil";

type Faq = {
  title: string | JSX.Element;
  body: string | JSX.Element;
};

type TFaqSection = {
  section: string;
  list: Faq[];
};

export type TFaqList = TFaqSection[];

const TextParagraph = ({ children }) => (
  <TextParagraphOriginal fontSize="md" my={3} color={"utils.black"}>
    {children}
  </TextParagraphOriginal>
);

const Ul = ({ children }) => <UnorderedList>{children}</UnorderedList>;

const Li = ({ children }) => <ListItem>{children}</ListItem>;

export const faqList: TFaqList = [
  {
    section: "Le più utili",
    list: [
      {
        title: "Che cos’è il Servizio Clienti e come posso contattarlo?",
        body: (
          <>
            <TextParagraph>
              È un contatto concreto e diretto con Hyundai Capital Bank Europe
              GmbH Italy.
            </TextParagraph>

            <TextParagraph>
              Compila il nostro{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>{" "}
              e ti aiuteremo per qualsiasi tua richiesta. In alternativa, cerca
              i nostri recapiti nelle sezioni dedicate.
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Utilizzare il Servizio Clienti ha un costo?",
        body: (
          <>
            <TextParagraph>
              La chiamata da rete fissa al numero 848.800.259 prevede un
              addebito pari a 1,452 / 0,830 (fascia oraria A/fascia oraria B)
              centesimi al minuto IVA inclusa, con uno scatto alla risposta di
              8,003 centesimi. Per la chiamata da rete mobile la tariffa è
              definita dal singolo gestore.
            </TextParagraph>
            <Ul>
              <Li>
                <strong>Fascia oraria A</strong>: LU-VE dalle 08:00 alle 18:30.
                SAB dalle 08:00 alle 13:00.
              </Li>
              <Li>
                <strong>Fascia oraria B</strong>: VE dalle 18:30 alle 08:00, SAB
                dalle 13:00 alle 08:00, DOM e FESTIVI tutto il giorno
              </Li>
            </Ul>
          </>
        ),
      },
      {
        title: "Cosa è la Lettera di Conferma Contratto?",
        body: (
          <>
            <TextParagraph>
              È un documento ufficiale inviato dopo la liquidazione della
              pratica che certifica l’attivazione del contratto. Su tale
              comunicazione è riportato il numero del contratto che è utile per
              l'accesso alla sua Area Riservata.
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Posso avere una certificazione sullo stato pagamenti?",
        body: (
          <TextParagraph>
            Richiedere lo stato dei pagamenti è molte semplice, basta compilare
            il
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              form
            </StyledLink>
            . Ogni richiesta deve essere corredata della copia del documento di
            identità fronte/retro e un numero di recapito al quale essere
            contattato in caso di necessità.
          </TextParagraph>
        ),
      },
      {
        title:
          "In cosa consiste l’estinzione anticipata del finanziamento e come posso richiederla?",
        body: (
          <>
            <TextParagraph>
              L’estinzione anticipata consiste nella facoltà per il consumatore
              di rimborsare anticipatamente in qualsiasi momento, in tutto o in
              parte, l'importo dovuto alla Banca. In tal caso il consumatore ha
              diritto a una riduzione del costo totale del credito, pari
              all'importo degli interessi e dei costi dovuti per la vita residua
              del contratto. In caso di rimborso anticipato, la Banca ha diritto
              a un indennizzo pari all'1 per cento dell'importo rimborsato in
              anticipo, se la vita residua del contratto è superiore a un anno,
              ovvero lo 0,5 per cento del medesimo importo, se la vita residua
              del contratto è pari o inferiore a un anno.
            </TextParagraph>
            <TextParagraph>
              Puoi fare richiesta per ottenere il conteggio di estinzione in
              autonomia direttamente dalla tua area riservata oppure tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Posso variare la mia modalità di pagamento da Bollettino Postale a SDD (addebito conto corrente)?",
        body: (
          <>
            <TextParagraph>
              Certamente, è possibile richiedere la variazione tramite:
            </TextParagraph>
            <Ul>
              <Li>
                <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                  il form
                </StyledLink>
              </Li>
              <Li>la funzione dedicata all’interno della sua Area Riservata</Li>
              <Li>l’apposito modulo SDD</Li>
            </Ul>
          </>
        ),
      },
      {
        title: "Come posso comunicare le mie nuove coordinate bancarie?",
        body: (
          <>
            <TextParagraph>
              Puoi comunicare le nuove coordinate utilizzando l'apposita
              funzione presente nella tua Area Riservata oppure utilizzando il
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>

            <TextParagraph>
              Si ricorda che le coordinate comunicate devono corrispondere ad un
              conto corrente intestato ad una delle persone che compaiono sul
              contratto (Cliente, coobbligato, garante).
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Come posso effettuare un pagamento di una o più rate in autonomia?",
        body: (
          <>
            <TextParagraph>
              Oltre alle modalità di rimborso previste dal contratto (Bollettino
              Postale premarcato – Addebito SDD) è possibile effettuare un
              pagamento tramite:
            </TextParagraph>
            <Ul>
              <Li>
                Bollettino postale non premarcato:
                <Ul>
                  <Li>
                    NTESTAZIONE:{" "}
                    <strong>Hyundai Capital Bank Europe GmbH Italy</strong>,{" "}
                    corso Massimo D'Azeglio 33/E CAP 10126 Torino
                  </Li>
                  <Li>
                    C/C POSTALE: <strong>1056693813</strong>
                  </Li>
                  <Li>
                    CAUSALE: indicare il n° della pratica di finanziamento
                    (indicato nella lettera di conferma contratto) oppure
                    cognome e nome del cliente;
                  </Li>
                </Ul>
              </Li>
              {/* TODO: remove after 2021-12-07 */}
              <HideUntil date="2021-12-07">
                <Li>
                  Bonifico bancario:
                  <Ul>
                    <Li>
                      INTESTAZIONE:{" "}
                      <strong>Hyundai Capital Bank Europe GmbH Italy</strong>{" "}
                      corso Massimo D'Azeglio 33/E CAP 10126 Torino
                    </Li>
                    <Li>
                      IBAN: <strong>IT51X0366401000000000000404</strong>
                    </Li>
                    <Li>
                      CAUSALE: indicare il n° della pratica di finanziamento
                      (indicato nella lettera di conferma contratto) oppure
                      cognome e nome del cliente;
                    </Li>
                  </Ul>
                </Li>
              </HideUntil>
            </Ul>
          </>
        ),
      },
      {
        title:
          "Cosa devo fare se ho smarrito i bollettini postali per pagare il finanziamento / prestito oppure non li ho mai ricevuti?",
        body: (
          <>
            <TextParagraph>
              Non ti preoccupare, abbiamo diverse soluzioni per risolvere questo
              problema
            </TextParagraph>
            <Ul>
              <Li>
                Accedere alla tua Area Riservata e richiedere in autonomia la
                ristampa dei bollettini
              </Li>
              <Li>
                Compilare il{" "}
                <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                  form
                </StyledLink>
              </Li>
            </Ul>
            <TextParagraph>
              Se la scadenza della prossima rata è vicina, non perdere tempo.
              Esistono diversi modi per effettuare il pagamento, li troverai
              elencati qui per tua comodità.
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Cosa devo fare per richiedere una copia del contratto o della documentazione bancaria (piano d'ammortamento, estratto conto)?",
        body: (
          <>
            <TextParagraph>
              I documenti contrattuali li puoi trovare, sempre disponibili,
              all'interno della tua Area Riservata oppure li puoi richiedere
              tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>
            <TextParagraph>
              Ogni richiesta deve essere corredata della copia del documento di
              identità fronte/retro e un numero di recapito al quale essere
              contattato in caso di necessità.
              <br />
              Le ricordiamo, inoltre, che il rilascio della copia del documento
              richiesto può essere assoggettato alla richiesta di rimborso spese
              per la produzione (rif. art. 119 del D.lgs. 385/1993).
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Posso variare i miei dati e recapiti anagrafici? E come?",
        body: (
          <>
            <TextParagraph>
              Li puoi variare in autonomia utilizzando la tua Area Riservata
              oppure facendo apposita richiesta tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form.
              </StyledLink>
              .
            </TextParagraph>

            <TextParagraph>
              Ogni richiesta deve essere corredata della copia del documento di
              identità fronte/retro e un numero di recapito al quale essere
              contattato in caso di necessità.
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Cosa devo fare per richiedere la lettera liberatoria?",
        body: (
          <>
            <TextParagraph>
              I documenti contrattuali li può trovare, sempre disponibili,
              all'interno della tua Area Riservata oppure li puoi richiedere
              tramite il
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>
            <TextParagraph>
              Ogni richiesta deve essere corredata della copia del documento di
              identità fronte/retro e un numero di recapito al quale essere
              contattato in caso di necessità.
              <br />
              La lettera liberatoria può essere intestata a tutte le persone che
              compaiono sul contratto (cliente, coobbligato, ecc).
            </TextParagraph>
          </>
        ),
      },
    ],
  },
  {
    section: "Accesso area riservata",
    list: [
      {
        title:
          "Esiste un'area riservata ai clienti a mia disposizione con tutte le informazioni dei miei contratti?",
        body: (
          <TextParagraph>
            Si, l'area riservata ai clienti la puoi trovare all'interno del
            nostro sito.
          </TextParagraph>
        ),
      },
      {
        title:
          "Quali credenziali sono necessarie per l'accesso alla mia Area Riservata?",
        body: (
          <>
            <TextParagraph>
              Per registrarsi all'area riservata sono necessari:
            </TextParagraph>
            <Ul>
              <Li>
                il Numero di contratto: si tratta del numero di finanziamento
                riportato nella Welcome Letter e sulla documentazione
                contrattuale
              </Li>
              <Li>il tuo Codice Fiscale</Li>
              <Li>il tuo Numero di telefono cellulare.</Li>
            </Ul>
            <TextParagraph>
              Sarà poi sufficiente seguire le indicazioni.
            </TextParagraph>
          </>
        ),
      },
    ],
  },
  {
    section: "Leasing",
    list: [
      {
        title: "Posso chiedere una copia delle fatture emesse?",
        body: (
          <>
            <TextParagraph>
              Si, è possibile fare richiesta tramite:
            </TextParagraph>
            <Ul>
              <Li>il tuo Cassettino Fiscale</Li>
              <Li>
                il
                <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                  form
                </StyledLink>
              </Li>
            </Ul>
          </>
        ),
      },
      {
        title: "Posso chiedere una copia del piano di ammortamento?",
        body: (
          <>
            <TextParagraph>
              Si, è possibile tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Posso chiedere un duplicato della lettera di riscatto?",
        body: (
          <>
            <TextParagraph>
              Si, è possibile tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Posso conoscere l’importo residuo per estinguere anticipatamente il mio Leasing?",
        body: (
          <>
            <TextParagraph>
              Si, è possibile tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .<br />
              Ricordiamo di specificare se il richiedente è un concessionario
              oppure un soggetto privato
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Posso richiedere una copia del contratto leasing?",
        body: (
          <>
            <TextParagraph>
              Si, è possibile tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Posso cambiare le coordinate bancarie per il mio contratto di Leasing?",
        body: (
          <>
            <TextParagraph>
              Si, è possibile tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Ho riscattato un veicolo oggetto di un contratto Leasing. Quanto tempo dovrò attendere per ricevere i documenti relativi al passaggio di proprietà?",
        body: (
          <>
            <TextParagraph>
              Dalla ricezione della documentazione e del bonifico da te
              effettuato fino alla notifica dell’avvenuto passaggio di proprietà
              trascorrono circa 20 gg.
            </TextParagraph>
            <TextParagraph>
              Per maggiori informazioni potrai contattare: <br />
              AGENZIA ITALIA - UFFICIO CERTIFICATI DI PROPRIETA'
              <br />
              Agenzia Italia S.p.A. Rif. Hyundai Capital Bank Europe GmbH Italy,
              <br />
              Via Vittorio Alfieri, 1 cap 31015 CONEGLIANO (TV Tel. 0438-368270
              E-MAIL: cdp.hcbe@agita.it"
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Ho subito il furto del veicolo. A chi posso rivolgermi?",
        body: (
          <>
            <TextParagraph>
              Devi contattare l'Autorità Giudiziaria per la denuncia del furto.
              Successivamente, dovrai inviare al seguente indirizzo:{" "}
              <strong>Hyundai Capital Bank Europe GmbH Italy</strong> - Servizio
              Postvendita Leasing - Corso Massimo D'Azeglio, 33/E - 10126
              Torino:
            </TextParagraph>
            <Ul>
              <Li>
                - L'originale della denuncia, insieme agli estremi del contratto
                di Leasing
              </Li>
              <Li>
                - Nome, indirizzo dell'agenzia, numero di fax della compagnia
                assicurativa
              </Li>
            </Ul>
          </>
        ),
      },
      {
        title:
          "Ho subito un danno in seguito ad un sinistro stradale. Come posso ricevere l'autorizzazione alla liquidazione?",
        body: (
          <>
            <TextParagraph>
              E' possibile fare richiesta tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              . <br />
              E’ necessario comunicare il nome della tua Compagnia assicurativa,
              l’indirizzo dell'agenzia e recapiti della compagnia assicurativa
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Cosa devo fare per ridurre il valore del massimale furto/incendio?",
        body: (
          <>
            <TextParagraph>
              E' possibile fare richiesta tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              . <br />
              E’ necessario comunicare il nome della tua Compagnia assicurativa,
              l’indirizzo dell'agenzia e recapiti della compagnia assicurativa
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Cosa devo fare per Svincolare la polizza assicurativa RCA o furto/incendio in seguito al riscatto/subentro?",
        body: (
          <>
            <TextParagraph>
              E' possibile fare richiesta tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              . <br />
              E’ necessario comunicare il nome della tua Compagnia assicurativa,
              l’indirizzo dell'agenzia e recapiti della compagnia assicurativa
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Cosa devo fare per Cambiare la compagnia assicurativa per la polizza RCA o furto/incendio?",
        body: (
          <>
            <TextParagraph>
              E' possibile fare richiesta tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              . <br />È necessario comunicare i dati della compagnia
              assicurativa vecchia e di quella nuova; nello specifico il nome
              della Compagnia assicurativa, l’indirizzo e i recapiti telefonici.
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Cosa sono gli ultimi addebiti SDD, richiesti tra la documentazione da presentare in fase di riscatto o estinzione anticipata?",
        body: (
          <>
            <TextParagraph>
              Si tratta della copia dei movimenti di pagamento degli ultimi 3
              canoni che possono essere richiesti presso la propria banca oppure
              possono essere evidenziati su una copia del proprio estratto
              conto. <br />
              Le fatture dei canoni inviate mensilmente da Hyundai Capital Bank
              Europe GmbH Italy non costituiscono ricevuta di pagamento.
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          'Che cosa si intende per "Compilazione del modello TT2120 nella parte anagrafica" in fase di riscatto o estinzione anticipata?',
        body: (
          <>
            <TextParagraph>
              Il modello denominato “TT2120”, necessario per la pratica di
              passaggio di proprietà , deve essere compilato inserendo solo i
              dati personali o quelli del legale rappresentante della società.
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Come posso comunicare che la società intestataria del contratto di Leasing ha cambiato ragione sociale?",
        body: (
          <>
            <TextParagraph>
              E' possibile fare comunicate la variazione della ragione sociale
              tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Cosa devo fare per richiedere la lettera di fine finanziamento (liberatoria)?",
        body: (
          <>
            <TextParagraph>
              E' possibile fare richiesta tramite il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>
          </>
        ),
      },
    ],
  },
  {
    section: "Prestiti auto/finalizzati",
    list: [
      {
        title: "Che cos’è il Servizio Clienti e come posso contattarlo?",
        body: (
          <>
            <TextParagraph>
              È un contatto concreto e diretto con Hyundai Capital Bank Europe
              GmbH Italy. Compila{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                il nostro form
              </StyledLink>{" "}
              e ti aiuteremo per qualsiasi tua richiesta. In alternativa, cerca
              i nostri recapiti nelle sezioni dedicate.
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Utilizzare il Servizio Clienti ha un costo?",
        body: (
          <>
            <TextParagraph>
              La chiamata da rete fissa al numero <strong>848.800.259</strong>{" "}
              prevede un addebito pari a 1,452 / 0,830 (fascia oraria A/fascia
              oraria B) centesimi al minuto IVA inclusa, con uno scatto alla
              risposta di 8,003 centesimi. Per la chiamata da rete mobile la
              tariffa è definita dal singolo gestore.
            </TextParagraph>
            <Ul>
              <Li>
                Fascia oraria A: LU-VE dalle 08:00 alle 18:30. SAB dalle 08:00
                alle 13:00.
              </Li>
              <Li>
                Fascia oraria B: VE dalle 18:30 alle 08:00, SAB dalle 13:00 alle
                08:00, DOM e FESTIVI tutto il giorno
              </Li>
            </Ul>
          </>
        ),
      },
      {
        title: "Cosa è la Lettera di Conferma Contratto?",
        body: (
          <>
            <TextParagraph>
              È un documento ufficiale inviato dopo la liquidazione della
              pratica che certifica l’attivazione del contratto. Su tale
              comunicazione è riportato il numero del contratto che è utile per
              l'accesso alla sua Area Riservata.
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Che cos’è l’Area Riservata e come vi posso accedere?",
        body: (
          <>
            <TextParagraph>
              Se sei nostro cliente puoi verificare, ad esempio, lo stato
              pagamenti dei tuoi contratti, puoi visionare i tuoi documenti
              contrattuali, ecc.. L’area è interattiva e potrai fare tante altre
              richieste. Scopri quali, ti servono soltanto il tuo Numero di
              contratto, il codice fiscale e il tuo Numero di telefono
              cellulare. Clicca qui per registrarti. Per altre informazioni su
              accesso e registrazione, consulta la sezione FAQ.
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Cosa devo fare per richiedere una copia del contratto o della documentazione bancaria?",
        body: (
          <>
            <TextParagraph>
              Puoi visionare la copia del tuo contratto direttamente dalla tua
              Area Riservata oppure compilando il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>
            <TextParagraph>
              Ogni richiesta deve essere corredata della copia del documento di
              identità fronte/retro e un numero di recapito al quale essere
              contattato in caso di necessità. <br />
              Le ricordiamo, inoltre, che il rilascio della copia del documento
              richiesto può essere assoggettato alla richiesta di rimborso spese
              per la produzione (rif. art. 119 del D.lgs. 385/1993).
            </TextParagraph>
          </>
        ),
      },
      {
        title: "Posso avere una certificazione sullo stato pagamenti?",
        body: (
          <>
            <TextParagraph>
              Puoi consultare il tuo estratto conto diettamente dalla tua Area
              Riservata oppure chiedere copia certificazione sullo stato
              pagamenti compilando il{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form
              </StyledLink>
              .
            </TextParagraph>
            <TextParagraph>
              Ogni richiesta deve essere corredata della copia del documento di
              identità fronte/retro e un numero di recapito al quale essere
              contattato in caso di necessità
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Cosa devo fare se ho smarrito i bollettini postali per pagare il finanziamento / prestito oppure non li ho mai ricevuti?",
        body: (
          <>
            <TextParagraph>
              Non ti preoccupare, abbiamo diverse soluzioni per risolvere questo
              problema
            </TextParagraph>
            <Ul>
              <Li>
                Accedere alla tua Area Riservata e richiedere in autonomia la
                ristampa dei bollettini
              </Li>
              <Li>
                Compilare il{" "}
                <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                  form
                </StyledLink>
              </Li>
            </Ul>
            <TextParagraph>
              Se la scadenza della prossima rata è vicina, non perdere tempo.
              Esistono diversi modi per effettuare il pagamento, li troverai
              elencati qui per tua comodità.
            </TextParagraph>
          </>
        ),
      },
      {
        title:
          "Come posso effettuare un pagamento di una o più rate in autonomia?",
        body: (
          <>
            <TextParagraph>
              Oltre alle modalità di rimborso previste dal contratto (Bollettino
              Postale premarcato – Addebito SDD) è possibile effettuare un
              pagamento tramite:{" "}
            </TextParagraph>
            <Ul>
              <Li>
                Bollettino postale non premarcato:
                <Ul>
                  <Li>
                    INTESTAZIONE: Hyundai Capital Bank Europe GmbH Italy corso
                    Massimo D'Azeglio 33/E CAP 10126 Torino
                  </Li>
                  <Li>C/C POSTALE: 1056693813</Li>
                  <Li>
                    CAUSALE: indicare il n° della pratica di finanziamento
                    (indicato nella lettera di conferma contratto) oppure
                    cognome e nome del cliente;
                  </Li>
                </Ul>
              </Li>
              <Li>
                Bonifico bancario{" "}
                <Ul>
                  <Li>
                    INTESTAZIONE: Hyundai Capital Bank Europe GmbH Italy corso
                    Massimo D'Azeglio 33/E CAP 10126 Torino
                  </Li>
                  <Li>IBAN IT51X0366401000000000000404</Li>
                  <Li>
                    CAUSALE: indicare il n° della pratica di finanziamento
                    (indicato nella lettera di conferma contratto) oppure
                    cognome e nome del cliente
                  </Li>
                </Ul>
              </Li>
            </Ul>
          </>
        ),
      },
      // 44
      {
        title: "Cosa devo fare se non vedo l’addebito sul conto corrente?",
        body: (
          <>
            <TextParagraph>
              Nel caso di mancato addebito delle rate sul conto corrente, è
              necessario contattare Hyundai Capital Bank Europe GmbH Italy
              tramite{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                il form Contatti.
              </StyledLink>{" "}
              Se vuoi provvedere al pagamento in autonomia:
            </TextParagraph>
            <Ul>
              <Li>
                Bollettino postale non premarcato:
                <Ul>
                  <Li>
                    INTESTAZIONE: Hyundai Capital Bank Europe GmbH Italy corso
                    Massimo D'Azeglio 33/E CAP 10126 Torino
                  </Li>
                  <Li>C/C POSTALE: 1056693813</Li>
                  <Li>
                    CAUSALE: indicare il n° della pratica di finanziamento
                    (indicato nella lettera di conferma contratto) oppure
                    cognome e nome del cliente;
                  </Li>
                </Ul>
              </Li>

              <Li>
                Bonifico bancario
                <Ul>
                  <Li>
                    INTESTAZIONE: Hyundai Capital Bank Europe GmbH Italy corso
                    Massimo D'Azeglio 33/E CAP 10126 Torino
                  </Li>
                  <Li>IBAN IT51X0366401000000000000404</Li>
                  <Li>
                    CAUSALE: indicare il n° della pratica di finanziamento
                    (indicato nella lettera di conferma contratto) oppure
                    cognome e nome del cliente;
                  </Li>
                </Ul>
              </Li>
            </Ul>
          </>
        ),
      },
      // 45
      {
        title:
          "Ho finanziato l'acquisto di un auto con il vincolo assicurativo. Desidero ottenere una riduzione del massimale assicurato, o lo svincolo assicurativo, o cambiare la compagnia assicuratrice. Cosa devo fare?",
        body: (
          <>
            <TextParagraph>
              Per inoltrare la richiesta compila{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                il form.
              </StyledLink>
              <br />
              Assicurati di avere disponibili le seguenti informazioni in quanto
              ti verranno richieste durante la compilazione della richiesta:
            </TextParagraph>
            <Ul>
              <Li>
                Nome della nuova compagnia di assicurazione, agenzia (indirizzo)
                e numero di fax
              </Li>
              <Li>
                Nome della vecchia assicurazione, agenzia (indirizzo) e numero
                di fax
              </Li>
              <Li>Numero della vecchia polizza</Li>
            </Ul>
            <TextParagraph>
              Ti ricordiamo inoltre che solo per la riduzione del massimale
              assicurato esiste una apposita funzione sulla sua area riservata
              disponibile all’interno del nostro sito.
              <br />
              Provvederemo ad inviare il nulla osta al cambio di assicurazione
              con un'apposita lettera inviata ad entrambe le compagnie. Si
              ricorda che lo svincolo assicurativo potrà essere concesso solo
              dopo il pagamento di tutte le rate
            </TextParagraph>
          </>
        ),
      },
      // 46
      {
        title:
          "L'addebito della prima rata è superiore all’importo delle rate successive, perché?",
        body: (
          <TextParagraph>
            Come indicato nelle clausole del contratto, la prima rata è
            maggiorata in quanto comprende l'imposta di bollo e l’addebito
            relativo all'invio della lettera di conferma contratto. Se indicato
            nel contratto possono essere comprese anche le spese di istruttoria
            o l’imposta sostitutiva
          </TextParagraph>
        ),
      },
      // 47
      {
        title:
          "Ho scelto di pagare tramite addebito bancario SDD, ma ho anche ricevuto i bollettini postali. Cosa devo fare?",
        body: (
          <TextParagraph>
            Puoi verificare la tua modalità di rimborso rate direttamente dalla
            tua Area Riservata e procedure in autonomia all'eventuale
            variazione. In alternativa possiamo verificare noi qual è la
            modalità di pagamento corretta per il rimborso delle tue rate.{" "}
            <br />
            Puoi inviarci la richiesta di verifica tramite{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>
          </TextParagraph>
        ),
      },
      // 48
      {
        title:
          "Ho chiesto un finanziamento e mi è stato rifiutato. Quali sono i motivi?",
        body: (
          <TextParagraph>
            Generalmente il rifiuto può essere determinato dall'importo
            richiesto e/o dal contesto generale della pratica. Qualora
            desiderassi informazioni più specifiche, puoi inoltrare una
            richiesta compilando{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>{" "}
            Nel rispetto della normativa Privacy verrà inviata una comunicazione
            ufficiale, presso il domicilio, contenente le motivazioni del
            rifiuto.
          </TextParagraph>
        ),
      },
      // 49
      {
        title: "Come posso comunicare la variazione di indirizzo?",
        body: (
          <TextParagraph>
            È necessario comunicare il nuovo indirizzo compilando{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>
            <br />
            Le ricordiamo inoltre che è presente una funzione dedicata nella
            propria area clienti
          </TextParagraph>
        ),
      },
      // 50
      {
        title: "Voglio estinguere il mio finanziamento, cosa devo fare?",
        body: (
          <TextParagraph>
            Puoi ottenere il conteggio di estinzione anticipata direttamente
            dalla tua Area Riservata, oppure puoi farne richiesta tramite{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>{" "}
            Risponderemo fornendo tutte le informazioni sulla procedura da
            seguire e sull'importo esatto da versare.
          </TextParagraph>
        ),
      },
      // 51
      {
        title: "Come posso comunicare le mie nuove coordinate bancarie?",
        body: (
          <TextParagraph>
            Puoi modificare le tue coordinate bancarie tramite la tua Area
            Riservata oppure comunicarle tramite{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>{" "}
            <br />
            È necessario comunicare ABI -- CAB -- C/C -- intestatario -- CIN --
            CODICE IBAN
            <br />
            Si ricorda che le coordinate comunicate devono corrispondere ad un
            conto corrente intestato ad una delle persone che compaiono sul
            contratto (Cliente, coobbligato, garante).
          </TextParagraph>
        ),
      },
      // 52
      {
        title:
          "Posso variare la mia modalità di pagamento da Bollettino Postale a SDD (addebito conto corrente)?",
        body: (
          <>
            <TextParagraph>
              Certamente, è possibile richiedere la variazione tramite:
            </TextParagraph>
            <Ul>
              <Li>
                <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                  il form.
                </StyledLink>
              </Li>
              <Li>la funzione dedicata all’interno della sua Area Riservata</Li>
              <Li>l’apposito modulo SDD</Li>
            </Ul>
          </>
        ),
      },
      // 53
      {
        title:
          "Ho cambiato indirizzo: posso continuare ad utilizzare i vecchi bollettini?",
        body: (
          <TextParagraph>
            Certamente! Non dimenticare però di trasmettere al più presto la
            variazione di indirizzo utilizzando la tua Area Riservata.
          </TextParagraph>
        ),
      },
      // 54
      {
        title:
          'Ho ricevuto una lettera con oggetto "Comunicazioni periodiche alla clientela". Che cosa significa?',
        body: (
          <TextParagraph>
            In base all'art. 119 del D.Lgs n. 385/1993 (testo Unico Bancario)
            Hyundai Capital Bank Europe GmbH Italy è tenuta a inviare ai Clienti
            un rendiconto annuale contenente una lista movimenti di un periodo
            di riferimento e un documento di sintesi delle principali condizioni
            contrattuali. Si precisa che tale documento potrà non riportare
            tutti i versamenti eseguiti, in quanto si prendono in considerazione
            solo i movimenti contabili di un determinato periodo.
            <br />
            L'invio di tale comunicazione comporta un addebito il cui importo è
            riportato sul modulo SECCI. In caso di mancato ricevimento, è
            possibile richiederne una copia tramite{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>
          </TextParagraph>
        ),
      },
      // 55
      {
        title:
          "Cosa devo fare per richiedere l'emissione della fattura relativa alla polizza assicurativa?",
        body: (
          <TextParagraph>
            Puoi contattare il Servizio Clienti tramite{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>
          </TextParagraph>
        ),
      },
      // 56
      {
        title:
          "Cosa devo fare per richiedere la copia della fattura relativa alla polizza assicurativa?",
        body: (
          <TextParagraph>
            La fattura relativa alla polizza assicurativa deve essere richiesta
            direttamente alla compagnia di assicurazione.
            <br />
            Puoi contattare il Servizio Clienti tramite{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>
            <br />
            Per conoscere il recapito da contattare utilizza lo spazio a
            disposizione nel{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              form di richiesta,
            </StyledLink>{" "}
            oppure chiama il numero 848.800.259 per chiedere assistenza
          </TextParagraph>
        ),
      },
      // 57
      {
        title: "Cosa devo fare per richiedere la lettera liberatoria?",
        body: (
          <TextParagraph>
            I documenti contrattuali li può trovare, sempre disponibili,
            all'interno della tua Area Riservata oppure li puoi richiedere
            tramite{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>
            <br />
            Ogni richiesta deve essere corredata della copia del documento di
            identità fronte/retro e un numero di recapito al quale essere
            contattato in caso di necessità.
            <br />
            La lettera liberatoria può essere intestata a tutte le persone che
            compaiono sul contratto (cliente, coobbligato, ecc).
          </TextParagraph>
        ),
      },
      // 58
      {
        title:
          "È possibile richiedere la variazione della scadenza della rata?",
        body: (
          <TextParagraph>
            Certamente, basta compilare{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>
            <br />
            Ti ricordiamo che esistono 2 possibili date di addebito delle rate:
            l’1 oppure il 15 del mese.
            <br />
            La variazione della scadenza può essere richiesta solo 1 volta per
            tutta la durata del contratto
          </TextParagraph>
        ),
      },
      // 59
      {
        title:
          "Vorrei richiedere la cancellazione/aggiornamento delle mie banche dati. Cosa devo fare?",
        body: (
          <TextParagraph>
            È sufficiente inoltrare richiesta scritta tramite l'indirizzo
            e-mail:{" "}
            <a href="mailto:accessodati@it.hcs.com">accessodati@it.hcs.com</a>
          </TextParagraph>
        ),
      },
      // 60
      {
        title:
          "Cosa avviene in caso di decesso dell'intestatario del contratto, del coobbligato o del garante?",
        body: (
          <>
            <TextParagraph>
              È necessario inviare copia del certificato di morte e eventuale
              atto di rinuncia all’eredità tramite{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                il form.
              </StyledLink>
              , allegando copia del documento di identità fronte/retro.
              <br />
              In presenza di copertura assicurativa è indispensabile:
            </TextParagraph>
            <Ul>
              <Li>
                Continuare a pagare regolarmente le rate come da contratto
              </Li>
              <Li>Contattare l’assicurazione per l’apertura del sinistro.</Li>
            </Ul>
            <TextParagraph>
              Per conoscere il recapito da contattare utilizza lo spazio a
              disposizione nel{" "}
              <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
                form di richiesta,
              </StyledLink>{" "}
              oppure chiama il numero 848.800.259 per chiedere assistenza.
            </TextParagraph>
          </>
        ),
      },
      // 61
      {
        title: "Come faccio a richiedere la copia della polizza assicurativa?",
        body: (
          <TextParagraph>
            Richiedere una copia della polizza è molto semplice, basta compilare{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>
            <br />
            Ogni richiesta deve essere corredata della copia del documento di
            identità fronte/retro e un numero di recapito al quale essere
            contattato in caso di necessità.
            <br />
            Le ricordiamo, inoltre, che il rilascio della copia del documento
            richiesto può essere assoggettato alla richiesta di rimborso spese
            per la produzione (rif. art. 119 del D.lgs. 385/1993).
          </TextParagraph>
        ),
      },
      // 62
      {
        title:
          "Sono assicurato con una compagnia assicurativa offerta dalla vostra società e devo denunciare un sinistro, mi mancano i recapiti. A chi mi devo rivolgere?",
        body: (
          <>
            <TextParagraph>
              Puoi rivolgerti, in base alla tua polizza, ai seguenti partner
              assicurativi:
            </TextParagraph>
            <Ul>
              <Li>
                Allianz spa, <a href="tel:800 511 661">tel. 800 511 661</a>
              </Li>
              <Li>
                AXA Assicurazioni s.p.a.,{" "}
                <a href="tel:06 42 11 56 89">tel: 06 42 11 56 89</a>
              </Li>
              <Li>
                CNP Santander Insurance,{" "}
                <a href="tel:800 966 102">tel: 800 966 102</a>
              </Li>
              <Li>
                Europ Assistance,{" "}
                <a href="tel:800 06 97 43">tel. 800 06 97 43</a>, oppure puoi
                accedere al portale{" "}
                <a
                  href="https://sinistrionline.europassistance.it"
                  target="_blank"
                >
                  https://sinistrionline.europassistance.it
                </a>
                oppure scrivere ad Europ Assistance Italia S.p.A. – Piazza
                Trento, 8 - 20135 Milano - Ufficio Liquidazione Sinistri;
              </Li>
              <Li>
                Nobis s.p.a., <a href="tel:800 893 562">tel: 800 893 562</a>
              </Li>
              <Li>
                Zurich Connect, <a href="tel:800 893 990">tel: 800 893 990</a>
              </Li>
            </Ul>
          </>
        ),
      },
      // 63
      {
        title:
          "Sono contattato da società terze che dicono di chiamare per conto di Hyundai Capital Bank Europe GmbH Italy, le quali mi sollecitano il pagamento delle rate. È prassi corretta?",
        body: (
          <TextParagraph>
            Quando hai firmato il contratto, hai autorizzato Hyundai Capital
            Bank Europe GmbH Italy a comunicare i tuoi dati a società per
            finalità di recupero crediti collegate al contratto di
            finanziamento.
          </TextParagraph>
        ),
      },
      // 64
      {
        title: "Che cos’ è il diritto di recesso e come posso esercitarlo?",
        body: (
          <TextParagraph>
            Il diritto di recesso ( o di ripensamento) è previsto soltanto nei
            contratti di finanziamento stipulati da consumatori. Può essere
            esercitato entro 14 giorni, che decorrono dalla conclusione del
            contratto o, se successivo, dal momento in cui il consumatore riceve
            tutte le condizioni e le informazioni previste di legge.
            <br />
            Per poter recedere, occorre inviare una raccomandata AR a Hyundai
            Capital Bank Europe GmbH Italy, Corso Massimo D’Azeglio 33/E, 10126
            Torino. La comunicazione può essere inviata tramite{" "}
            <StyledLink variant={"faq"} as={GatsbyLink} to="/contatti">
              il form.
            </StyledLink>{" "}
            In tale ipotesi, la comunicazione di recesso dovrà essere confermata
            mediante lettera raccomandata con avviso di ricevimento entro le
            quarantotto ore successive. La raccomandata si intende spedita in
            tempo utile se consegnata all'ufficio postale accettante entro i
            termini suindicati. L'avviso di ricevimento non è comunque
            condizione essenziale per provare l'esercizio del diritto di
            recesso, che è efficace decorsi tre giorni dal suo ricevimento.
          </TextParagraph>
        ),
      },
      // 65
      {
        title: "Che differenza c’è tra TAN e TAEG?",
        body: (
          <TextParagraph>
            Il TAEG rappresenta lo strumento principale di trasparenza nei
            contratti di credito al consumo. E’ un indice armonizzato a livello
            comunitario che nelle operazioni di credito al consumo rappresenta
            il costo totale del credito a carico del consumatore, comprensivo
            degli interessi e di tutti gli altri oneri da sostenere per
            l’utilizzazione del credito stesso. Il TAEG è espresso in
            percentuale del credito concesso e su base annua. Deve essere
            indicato nella documentazione contrattuale e nei messaggi
            pubblicitari o nelle offerte comunque formulate.
            <br />
            Il TAN indica il tasso d'interesse (ossia il prezzo), in percentuale
            e su base annua, richiesto da un creditore sull'erogazione di un
            finanziamento. A differenza del TAEG (vedi) non esprime il "costo
            complessivo" del finanziamento che può essere anche molto più alto
            (ad esempio, per spese, oneri e commissioni accessorie). Pertanto un
            prestito con TAN pari a zero potrebbe avere un TAEG ben maggiore di
            zero.
          </TextParagraph>
        ),
      },
    ],
  },
];
