import { Heading, HeadingProps } from "@chakra-ui/react";
import React from "react";

export const Headline: React.FC<HeadingProps> = ({
  children,
  textAlign = "center",
  ...props
}) => (
  <Heading fontSize={"32px"} mb={8} textAlign={textAlign} {...props}>
    {children}
  </Heading>
);
