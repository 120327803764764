/* ---------------------------------
About Us
--------------------------------- */

import { Box, Heading, Image, SimpleGrid } from "@chakra-ui/react";
import React from "react";
import { MenuIds } from "../../../types/types";
import Section from "../../Section";
import { TextParagraph } from "../../TextParagraph";
import HCBE_logo from "../../../img/HCBE-logo-accent.svg";

// AboutUs
export const AboutUs: React.FC = () => (
  <>
    <Section id={MenuIds["Chi siamo"]} color={"utils.gray.02"}>
      <Box
        float={["none", null, "left"]}
        w={["full", null, 300]}
        m={[0, null, 10]}
        ml={0}
        textAlign={["center", null, "left"]}
      >
        <Heading
          as={"h4"}
          fontSize={[18, 24]}
          color={"utils.black"}
          mb={2}
          fontWeight={"400"}
          fontFamily={"body"}
        >
          Offrire soluzioni finanziarie{" "}
          <strong>semplici, personali e giuste</strong> per costruire
          <br /> con i clienti un rapporto di fiducia solido e duraturo.
        </Heading>
        {/* I'm keeping the grid in case we have to add another logo */}
        <SimpleGrid
          columns={{ sm: 1 }}
          spacing={10}
          mt={5}
          mb={[5, null, 0]}
          align="center"
        >
          <Image
            src={HCBE_logo}
            alt="Logo Hyundai Capital"
            w={"95%"}
            maxW={["240px", null, "initial"]}
          />
        </SimpleGrid>
      </Box>
      <Box>
        <TextParagraph>
          HCBE nasce con l’obiettivo di offrire ai clienti che vogliono
          acquistare una vettura Hyundai o Kia una soluzione di acquisto
          funzionale alle loro esigenze, innovativa e trasparente.
        </TextParagraph>
        <TextParagraph>
          Con HCBE è possibile accedere all’offerta di finanziamenti e il
          leasing di automobili presso tutti i concessionari della rete Hyundai
          e Kia. Tutte le soluzioni includono servizi assicurativi che
          completano l’offerta grazie a contenuti esclusivi e in grado di
          soddisfare tutte le esigenze del cliente, proteggendo la persona, il
          veicolo e accompagnando il cliente nella gestione dell’automobile in
          modo innovativo, responsabile e sostenibile.
        </TextParagraph>
        <TextParagraph>
          Grazie a HCBE, ogni cliente potrà accedere, in maniera agevole e
          semplice, a soluzioni finanziarie ed assicurative, personalizzate e
          trasparenti. Perché in HCBE il cliente è il centro intorno al quale
          vogliamo costruire un rapporto basato su fiducia, continuità e
          solidità.
        </TextParagraph>
        <TextParagraph>
          HCBE è una Joint Venture societaria, partecipata al 51% da Santander
          Consumer Bank AG e da Hyundai Capital Services per il restante 49%, fa
          parte del Gruppo Banco Santander e ha sede legale a Francoforte sul
          Meno friedrich-ebert-anlage 35-37 Germania TBC. HCBE è una società
          sottoposta al controllo e alla vigilanza della Bafin Numero di
          Registrazione BaFin 144 345. HCBE Italy, sede secondaria di HCBE, è
          sottoposta al controllo e alla vigilanza della Banca d'Italia,
          iscritta all’elenco degli intermediari della Unione Europea al numero
          EU00011544.
        </TextParagraph>
      </Box>
    </Section>
  </>
);
