import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";
import React from "react";
import { MOBILE_BREAKPOINT } from "../constants/ui";
import { Quote } from "./Quote";

export const Claim: React.FC = () => {
  const [isMobile] = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT}px)`);
  const fontSize = isMobile ? "md" : "2xl";

  return (
    <Flex
      as={"section"}
      id={"claim"}
      w="100%"
      padding="40px"
      background={"accent.01"}
      textColor={"utils.offWhite"}
      alignItems="center"
      justifyContent="center"
    >
      <Box textAlign="center">
        <Flex>
          <Quote>
            <Text
              fontSize={fontSize}
              fontWeight="bold"
              textColor={"utils.white"}
            >
              HCBE, l’alleanza strategica del mondo del credito al consumo
              automobilistico <br />
              nata per offrire ai clienti Hyundai e KIA soluzioni di acquisto
              flessibili e innovative
            </Text>
          </Quote>
        </Flex>
      </Box>
    </Flex>
  );
};
