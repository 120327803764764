import React from "react";
import { Helmet } from "react-helmet";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

type THead = {
  title?: string;
};

export const Head: React.FC<THead> = ({ title }) => {
  const { title: metaTitle, canonical } = useSiteMetadata();

  const fullTitle = title ? `${metaTitle} - ${title}` : metaTitle;

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta lang="it" />
      <title>{fullTitle}</title>
      <link rel="canonical" href={canonical} />
      <link rel="shortcut icon" href="favicon.ico" />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
    </Helmet>
  );
};
