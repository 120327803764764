import { navigate } from "gatsby";
import React from "react";
import BrandedButton from "../../BrandedButton";
import { Headline } from "../../Headline";
import Section from "../../Section";

export const AskAnExpert: React.FC = () => (
  <Section
    id={"contacts"}
    textAlign={"center"}
    containerProps={{
      padding: 8,
    }}
  >
    <Headline>Non hai trovato la risposta che cercavi?</Headline>

    {/** TODO replace w/ link */}
    <BrandedButton
      buttonLabel="Chiedi a un esperto"
      buttonAction={() => navigate("/chiedi-esperto/")}
      px={["30px", "60px"]}
      bg={"accent.01"}
      textTransform={"none"}
      fontWeight={"normal"}
      colorScheme={{
        text: "utils.white",
        border: "transparent",
      }}
    />
  </Section>
);
