/* ---------------------------------
Section
--------------------------------- */

import { Box, Container, ContainerProps, useTheme } from "@chakra-ui/react";
import React from "react";
import { MenuIds } from "../types/types";
import { Token } from "@chakra-ui/styled-system/dist/types/utils";
import * as CSS from "csstype";

type OwnProps = {
  bg?: string;
  id?: MenuIds | string;
  color?: string;
  containerProps?: ContainerProps & {
    maxWidth?: string;
    padding?: Token<CSS.Property.Padding | number, "space">;
  };
} & Record<string, unknown>;

const Section: React.FC<OwnProps> = ({
  bg,
  id,
  color,
  containerProps,
  children,
  ...props
}) => {
  const { maxWidth, padding, ...cProps } = containerProps || {};
  return (
    <Box as={"section"} bg={bg ?? "utils.gray.05"} id={id} {...props}>
      <Container
        maxW={maxWidth ?? "container.md"}
        py={padding ?? 24}
        color={color ?? "utils.black"}
        {...cProps}
      >
        {children}
      </Container>
    </Box>
  );
};

export default Section;
