import React from "react";
import { MenuIds } from "../../../../types/types";
import { EnabledStoreLocator } from "./EnabledStoreLocator";

export const StoreLocator: React.FC = () => {
  return (
    <section id={MenuIds["Dove trovarci"]}>
      <EnabledStoreLocator />
    </section>
  );
};
